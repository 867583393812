import { Footer, Anchor, Box, Button } from 'grommet';
import { FacebookOption, Instagram } from 'grommet-icons';

const AppFooter = ({gridArea}) => {
  return (
    <Footer gridArea={gridArea} justify='between' background="brand" pad="medium">
      <Anchor color='white' hoverIndicator href="/contatti">Contatti</Anchor>
      <Box direction='row'>
        <Button 
          icon={<FacebookOption color='white' />} 
          href='https://www.facebook.com/profile.php?id=100080170843034' />
        <Button 
          icon={<Instagram color='white' />} 
          href='https://www.instagram.com/cascinalingua/' />
      </Box>
    </Footer>
  )
}

export default AppFooter