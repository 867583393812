import React, { useState } from "react";
import { Grommet, Page, ResponsiveContext, Grid } from 'grommet';
import NavBar from './components/app_bar/NavBar'
import SideBar from './components/SideBar'
import HomePage from "./routes/HomePage";
import AppFooter from "./components/AppFooter";
import Gallery from "./routes/Gallery";
import { Route, Routes } from "react-router-dom";
import WhoAreWe from './routes/WhoAreWe';
import WhatDoWeDo from './routes/WhatDoWeDo';
import Contacts from './routes/Contacts';
import { grommetTheme } from "./resources/theme";
import Products from "./routes/Products";

function App() {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <Grommet theme={grommetTheme}>
      <Grid 
        style={{ minHeight: '100vh' }}
        rows={['70px', 'auto', '70px']}
        columns={['100%']}
        areas={[
          { name: 'navbar', start: [0, 0], end: [0, 0] },
          { name: 'page', start: [0, 1], end: [0, 1] },
          { name: 'footer', start: [0, 2], end: [0, 2] }, ]}>
        <NavBar setShowSidebar={setShowSidebar} gridArea='navbar' />  
        <Page gridArea='page'>
          <ResponsiveContext.Consumer>
            {size => (size === 'small' && showSidebar) && (
              <SideBar setShowSidebar={setShowSidebar}/>
            )}
          </ResponsiveContext.Consumer>
          <Routes>
            <Route path="/" element={
              <HomePage />
            } />
            <Route path="galleria" element={
              <Gallery />
            } />
            <Route path="chi-siamo" element={
              <WhoAreWe />
            } />
            <Route path="cosa-facciamo" element={
              <WhatDoWeDo />
            } />
            <Route path="contatti" element={
              <Contacts />
            } />
            <Route path="prodotti" element={
              <Products />
            } />
          </Routes>
        </Page>
        <AppFooter gridArea='footer'/>
      </Grid>
    </Grommet>
  );
}

export default App;
