import React from 'react'
import { Stack, Image, ResponsiveContext } from 'grommet';
import banner from '../resources/images/banner.jpeg';
import logo from '../resources/images/logo_without_text.svg';

const Banner = () => {
  const size = React.useContext(ResponsiveContext);
  const logoHeight = size === 'small' ? '300px' : '500px';
  return (
    <Stack fill alignSelf='center' anchor='center' height='600px'>
        <Image src={banner} fill fit='cover' alignSelf='center' />
        <Image src={logo} height={logoHeight}   />
    </Stack>
  )
}

export default Banner