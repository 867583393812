const WEBSITE_STRING_CONSTANTS = {
    SLIDER_HOMEPAGE : 'I NOSTRI PRODOTTI VENGONO RACCOLTI OGNI MATTINA PER MANTENERE LA LORO FRESCHEZZA',
    CASCINA_LINGUA_TITLE_HOMEPAGE: 'CASCINA LINGUA',
    CASCINA_LINGUA_DESC_HOMEPAGE: 'L’Azienda è nata nel 1989 da Lingua Giancarlo e Cometto Battistina, semplici produttori di frutta e verdura con una passione per gli animali. Con il passare degli anni la loro semplice passione e dedizione si è trasformata in un vero e proprio lavoro a servizio dei nostri clienti, dando loro dei prodotti unici e genuini a KM0.',
    ROUTER_CARD_1_TITLE: 'Chi Siamo',
    ROUTER_CARD_2_TITLE: 'Galleria',
    ROUTER_CARD_3_TITLE: 'Cosa Facciamo',
    BUTTON_SCOPRI: 'Scopri di più',
    SCOPRI_IL_TEAM_TITLE_HOMEPAGE: 'SCOPRI IL TEAM',
    SCOPRI_IL_TEAM_DESC_HOMEPAGE: 'Ognuno di noi ha un ruolo fondamentale e la nostra unione ci ha permesso di specializzarci nella coltivazione con metodi naturali (ci piace essere definiti contadini) di frutta e verdura e di usare le nostre materie prime migliori per creare delle prelibatezze all’ interno del nostro laboratorio con dei risultati eccellenti. Grazie ai nostri fedelissimi clienti i nostri due punti vendita sono diventati una piccola nicchia della zona. Tutto ciò ci rende orgogliosi e soddisfatti nonostante i grandi sacrifici che comporta questo tipo di lavoro!',
}

export default WEBSITE_STRING_CONSTANTS;
