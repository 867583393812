import React from 'react'
import { Box, Heading, Paragraph, ResponsiveContext} from 'grommet';
import ImageWithOffsetBackground from './ImageWithOffsetBackground';
import ResponsiveGrid from './ResponsiveGrid';

const DescriptionWithImage = ({ imgSrc, title, desc, inverse }) => {
    const size = React.useContext(ResponsiveContext);
    const imgRowSize = size === 'small' ? '300px' : '400px';
    const responsiveRows = inverse ? [imgRowSize, 'auto'] : ['auto', imgRowSize];

    let marginSize;
    switch (size) {
        case 'small':
            marginSize = { left: 'large', right: 'large', top: 'xlarge', bottom: 'xlarge' };
            break;
        case 'medium':
            marginSize = { left: 'large', right: 'large', top: 'xlarge', bottom: 'xlarge' };
            break;
        case 'large':
            marginSize = { left: 'xlarge', right: 'xlarge', top: 'xlarge', bottom: 'xlarge' };
            break;
        default:
            break;
    }
    let gapSize;
    switch (size) {
        case 'small':
            gapSize = { row: 'large', column: 'none' };
            break;
        case 'medium':
            gapSize = { row: 'none', column: 'large' };
            break;
        case 'large':
            gapSize = { row: 'none', column: 'xlarge' };
            break;
        default:
            break;
    }
    return (
        <ResponsiveGrid
            responsive={true}
            rows={responsiveRows}
            columns={['1fr', '1fr']}
            gap={gapSize}
            margin={marginSize}
            areas={{
                small: [
                    { name: 'left', start: [0, 0], end: [1, 0] },
                    { name: 'right', start: [0, 1], end: [1, 1] },
                ],
                medium: [
                    { name: 'left', start: [0, 0], end: [0, 1] },
                    { name: 'right', start: [1, 0], end: [1, 1] },
                ],
                large: [
                    { name: 'left', start: [0, 0], end: [0, 1] },
                    { name: 'right', start: [1, 0], end: [1, 1] },
                ],
            }}>
            <Box
                gridArea={ inverse ? 'right' : 'left'}
                align='center'
                direction='column'
                justify='center'>
                <Heading textAlign='center'>{title}</Heading>
                <Paragraph fill textAlign='center'>{desc}</Paragraph>
            </Box>
            <ImageWithOffsetBackground imgSrc={imgSrc} gridArea={ inverse ? 'left' : 'right'} />
        </ResponsiveGrid>
  )
}

export default DescriptionWithImage