import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom";
import { Image, Button, Box, ResponsiveContext } from 'grommet';

const RouterCard = ({title, backgroundImg, gridArea, href}) => {
    const size = React.useContext(ResponsiveContext);
    let imgHeight;
    switch (size) {
        case 'small':
            imgHeight = '220px' 
            break;
        case 'medium':
            imgHeight = '220px' 
            break;
        case 'large':
            imgHeight = '350px' 
            break;
        default:
            break;
    }
    const navigate = useNavigate();
    return (
        <Box gridArea={gridArea}>
            <Box pad='1px' background='white' height={imgHeight}>
                <Image src={backgroundImg} fit='cover'/>
            </Box>
            <Button 
                alignSelf='center'
                size='medium'
                margin={{ top: 'medium' }}
                label={title}
                onClick={() => navigate(href)}
                secondary/>
        </Box>
    )
}

RouterCard.propTypes = {
    title: PropTypes.string.isRequired,
    backgroundImg: PropTypes.string.isRequired
}

export default RouterCard