import { Grid, ResponsiveContext, Box, Heading } from 'grommet';
import ProductCard from '../components/ProductCard'
import PRODUCTS from '../resources/products_cards';
import React from 'react';

const Products = ({ gridArea }) => {
  const size = React.useContext(ResponsiveContext);
  let productsPerRow;
  switch (size) {
      case 'small':
          productsPerRow = 1
          break;
      case 'medium':
          productsPerRow = 3 
          break;
      case 'large':
          productsPerRow = 5
          break;
      default:
          break;
  }
  let marginSize;
    switch (size) {
        case 'small':
            marginSize = { left: 'large', right: 'large', top: 'medium', bottom: 'medium' };
            break;
        case 'medium':
            marginSize = { left: 'medium', right: 'medium', top: 'medium', bottom: 'medium' };
            break;
        case 'large':
            marginSize = { left: 'large', right: 'large', top: 'medium', bottom: 'medium' };
            break;
        default:
            break;
    }
    let gapSize;
    switch (size) {
        case 'small':
            gapSize = { row: 'medium', column: 'medium' };
            break;
        case 'medium':
            gapSize = { row: 'medium', column: 'medium' };
            break;
        case 'large':
            gapSize = { row: 'medium', column: 'medium' };
            break;
        default:
            break;
    }


  return (
    <Box margin='medium'>
      <Heading>
        I nostri prodotti
      </Heading>
      <Grid 
        gridArea={ gridArea }
        margin={marginSize}
        gap={gapSize}
        columns={{ count: productsPerRow, size: 'auto' }}>
        {PRODUCTS.map((image) => (
            <div key={image.id}>
                <ProductCard 
                    imgSrc={image.imgSrc}
                    title={image.title}
                    desc={image.desc} />
            </div>
        ))}
      </Grid>
    </Box>
    
  )
}

export default Products