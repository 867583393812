import React from 'react'
import { Box, Button, Heading, Image, Paragraph, ResponsiveContext } from 'grommet';
import labPic from '../resources/images/IlLaboratorio.jpg';
import ResponsiveGrid from './ResponsiveGrid';
import { useNavigate } from "react-router-dom";

const B2BSection = () => {
    const navigate = useNavigate();
    const text = (
        <>
            Nel 2018 abbiamo deciso di allargarci creando la nostra piccola nicchia chiamata LABORATORIO!
            È un locale , grazie alle condizione igenico-sanitarie controllate e ai macchinari e attrezzature , adibito all' attività di lavorazione, trasformazione, essicazione di ortaggi e frutta i cui prodotti finali sono conserve, salse, sughi, sottoli e sottaceti, composte e molte altre prelibatezze…
            La nostra è stata un idea di far diventare un mestiere quello che si faceva molti anni fa nelle cucine degli italiani usando  le ricette tramandate dai nonni per poter mantenere il sapore e la genuinità di una volta!!
            I nostri prodotti li potete trovare nei nostri punti vendita!
            Dal 2021 abbiamo concesso la possibilità ad altre aziende agricole di poter lavorare i loro prodotti nel nostro laboratorio!!
            Diamo anche la possibilità ai privati di poter creare le proprie composte 
        </>
      );
    const size = React.useContext(ResponsiveContext);
    const imgRowSize = size === 'small' ? '300px' : '400px';

    let marginSize;
    switch (size) {
        case 'small':
            marginSize = { left: 'xlarge', right: 'xlarge', top: 'xlarge', bottom: 'xlarge' };
            break;
        case 'medium':
            marginSize = { left: 'large', right: 'large', top: 'large', bottom: 'large' };
            break;
        case 'large':
            marginSize = { left: 'xlarge', right: 'xlarge', top: 'xlarge', bottom: 'xlarge' };
            break;
        default:
            break;
    }
    let gapSize;
    switch (size) {
        case 'small':
            gapSize = { row: 'large', column: 'none' };
            break;
        case 'medium':
            gapSize = { row: 'none', column: 'large' };
            break;
        case 'large':
            gapSize = { row: 'none', column: 'xlarge' };
            break;
        default:
            break;
    }
    let picMargin = size === 'small' ? 'none' : { left: 'none', right: 'none', top: 'xlarge', bottom: 'xlarge' };
    return (
        <Box 
            background='brand'>
            <ResponsiveGrid
                responsive={true}
                rows={[imgRowSize, 'auto']}
                columns={['1fr', '1fr']}
                margin={marginSize}
                gap={gapSize}
                areas={{
                    small: [
                        { name: 'image', start: [0, 0], end: [1, 0] },
                        { name: 'card', start: [0, 1], end: [1, 1] },
                    ],
                    medium: [
                        { name: 'image', start: [0, 0], end: [0, 1] },
                        { name: 'card', start: [1, 0], end: [1, 1] },
                    ],
                    large: [
                        { name: 'image', start: [0, 0], end: [0, 1] },
                        { name: 'card', start: [1, 0], end: [1, 1] },
                    ],
                }}>
                <Box gridArea='image' pad='1px' background='white' margin={picMargin}>
                    <Image src={labPic} 
                        gridArea='image'
                        style={{ width: '100%', objectFit: 'cover', height: '100%', objectPosition: 'left' }}  /> 
                </Box>      
                <Box gridArea='card' direction='column' align='center' justify='center'>
                    <Heading textAlign='center'>IL LABORATORIO</Heading>
                    <Paragraph fill textAlign='center'>
                        {text}
                    </Paragraph>
                    <Button 
                        alignSelf='center'
                        size='medium'
                        margin={{ top: 'medium' }}
                        label='Scopri i nostri prodotti'
                        onClick={() => navigate('/prodotti')}
                        secondary/>
                </Box>      
            </ResponsiveGrid>
        </Box>
    )
}

export default B2BSection