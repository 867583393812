import React from 'react';
import { Box, Image, Paragraph, Heading, ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types'

const DescriptivePage = ({mainImage, title, text }) => {
  const size = React.useContext(ResponsiveContext);
  const columnContent = (
    <Box pad='xlarge' direction='column'>
      <Box align='center' >
        <Image src={mainImage} fill='horizontal' />
      </Box>
      <Box>
        <Heading>
          {title}
        </Heading>
        <Paragraph fill>
          {text}
        </Paragraph>
      </Box>
    </Box>
  );
  const rowContent = (
    <Box pad='xlarge' direction='row'>
      <Box alignSelf='center' margin={{right: 'large'}} style={{ minWidth: '400px'}}>
        <Image src={mainImage} />
      </Box>
      <Box justify='center'>
        <Heading>
          {title}
        </Heading>
        <Paragraph fill>
          {text}
        </Paragraph>
      </Box>
    </Box>
  )
  return size === 'large' ?  rowContent : columnContent;
}

DescriptivePage.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.object.isRequired,
    mainImage: PropTypes.string.isRequired
}

export default DescriptivePage