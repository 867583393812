import photo1 from '../resources/images/gallery/photo_1.jpg';
import photo2 from '../resources/images/gallery/photo_2.jpg';
import photo3 from '../resources/images/gallery/photo_3.jpg';
import photo4 from '../resources/images/gallery/photo_4.jpg';
import photo5 from '../resources/images/gallery/photo_5.jpg';
import photo6 from '../resources/images/gallery/photo_6.jpg';
import photo7 from '../resources/images/gallery/photo_7.jpg';
import photo8 from '../resources/images/gallery/photo_8.jpg';
import photo9 from '../resources/images/gallery/photo_9.jpg';
import photo10 from '../resources/images/gallery/photo_10.jpg';
import photo11 from '../resources/images/gallery/photo_11.jpg';
import photo12 from '../resources/images/gallery/photo_12.jpg';
import photo13 from '../resources/images/gallery/photo_13.jpg';
import photo14 from '../resources/images/gallery/photo_14.jpg';
import photo15 from '../resources/images/gallery/photo_15.jpg';
import photo16 from '../resources/images/gallery/photo_16.jpg';
import photo17 from '../resources/images/gallery/photo_17.jpg';
import photo18 from '../resources/images/gallery/photo_18.jpg';
import photo19 from '../resources/images/gallery/photo_19.jpg';
import photo20 from '../resources/images/gallery/photo_20.jpg';
import photo21 from '../resources/images/gallery/photo_21.jpg';
import photo22 from '../resources/images/gallery/photo_22.jpg';
import photo23 from '../resources/images/gallery/photo_23.jpg';
import photo24 from '../resources/images/gallery/photo_24.jpg';
import photo25 from '../resources/images/gallery/photo_25.jpg';
import photo26 from '../resources/images/gallery/photo_26.jpg';

const IMAGES = [
    {
        id: '1',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo1,
            full: photo1
        }
    },
    {
        id: '2',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo2,
            full: photo2
        }
    },
    {
        id: '3',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo3,
            full: photo3
        }
    },
    {
        id: '4',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo4,
            full: photo4
        }
    },
    {
        id: '5',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo5,
            full: photo5
        }
    },
    {
        id: '6',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo6,
            full: photo6
        }
    },
    {
        id: '7',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo7,
            full: photo7
        }
    },
    {
        id: '8',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo8,
            full: photo8
        }
    },
    {
        id: '9',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo9,
            full: photo9
        }
    },
    {
        id: '10',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo10,
            full: photo10
        }
    },
    {
        id: '11',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo11,
            full: photo11
        }
    },
    {
        id: '12',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo12,
            full: photo12
        }
    },
    {
        id: '13',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo13,
            full: photo13
        }
    },
    {
        id: '14',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo14,
            full: photo14
        }
    },
    {
        id: '15',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo15,
            full: photo15
        }
    },
    {
        id: '16',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo16,
            full: photo16
        }
    },
    {
        id: '17',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo17,
            full: photo17
        }
    },
    {
        id: '18',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo18,
            full: photo18
        }
    },
    {
        id: '19',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo19,
            full: photo19
        }
    },
    {
        id: '20',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo20,
            full: photo20
        }
    },
    {
        id: '21',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo21,
            full: photo21
        }
    },
    {
        id: '22',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo22,
            full: photo22
        }
    },
    {
        id: '23',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo23,
            full: photo23
        }
    },
    {
        id: '24',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo24,
            full: photo24
        }
    },
    {
        id: '25',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo25,
            full: photo25
        }
    },
    {
        id: '26',
        caption: 'Test Caption',
        urls: {
            thumbnail: photo26,
            full: photo26
        }
    },

];

export default IMAGES;