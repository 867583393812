import React from 'react';
import { Button, Image, ResponsiveContext, Header, Box } from 'grommet';
import { Menu } from 'grommet-icons';
import PropTypes from 'prop-types'
import logo from '../../resources/images/logo.svg';
import { useNavigate } from "react-router-dom";
import NavBarAnchor from './NavBarAnchor';
 
const AppBar = ({ setShowSidebar, gridArea }) => {
    const size = React.useContext(ResponsiveContext);
    const navigate = useNavigate();

    return (
        <Header 
            id='navbar'
            background='brand'
            gridArea={gridArea}
            pad={{ horizontal: 'medium', vertical: 'xsmall' }}>
            <Box 
                onClick={() => navigate("/")} 
                focusIndicator={false}>
                <Image 
                    src={logo}
                    height='50px' />
            </Box>
            {size === 'small' ? (
                <Button
                    icon={<Menu />} 
                    onClick={() => setShowSidebar(true)} />
            ) : ( 
                <Box 
                    direction='row'
                    gap='medium'>
                    <NavBarAnchor href='/chi-siamo' title='Chi Siamo' />
                    <NavBarAnchor href='/galleria' title='Galleria' />
                    <NavBarAnchor href='/cosa-facciamo' title='Cosa Facciamo' />
                    <NavBarAnchor href='/prodotti' title='I Nostri Prodotti' />
                    <NavBarAnchor href='/contatti' title='Contatti' />
                </Box>
            )}
        </Header>
    )
}

AppBar.propTypes = {
    hideSideBar: PropTypes.func,
    showSidebar: PropTypes.bool
}
 
 export default AppBar