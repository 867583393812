import { Box, Paragraph, Heading } from 'grommet';
import { EmailForm } from '../components/EmailForm';
import Map from '../components/map/Map';

const Contacts = () => {
  return (
    <Box fill>
      <Box height='medium'>
        <Map />
      </Box>
      <Box pad='xlarge'>
        <Heading>Cascina Lingua SSA</Heading>
        <Paragraph>
          <strong>Indirizzo sede:<br/></strong>
          Via Tetto Brignone 48 Fraz. Spinetta - 12100 - Cuneo (CN) <br/>
          <br/>
          <strong>Punti Vendita<br/></strong>
          Via Spinetta 55 Cuneo, 12100 (CN)<br/>
          Dal Lunedì al Sabato<br/>
          h. 9.00 - h. 12.30<br/>
          h. 15.00 - h.19.30<br/>
          <br/>
          Via Massimo D'Azzeglio 9 Cuneo, 12100 (CN)<br/>
          Dal Lunedì al Sabato<br/>
          h. 9.00 - h. 12.30<br/>
          Lunedì, Martedì e Venerdì<br/>
          h. 16.00 - h.19.30<br/>
          <br/>
          <strong>Tel: </strong>+39 3381739965 - +39 3498864585<br/>
          <strong>Email: </strong>cascinalingua@libero.it<br/>
          <strong>P.IVA: </strong>00799740048<br/>
        </Paragraph>
        <Heading>Contattaci</Heading>
        <EmailForm />
      </Box>
    </Box>
  )
}

export default Contacts