import DescriptivePage from '../components/DescriptivePage'
import chiSiamoPic from '../resources/images/ChiSiamo.jpg';

const WhoAreWe = () => {
  const text = (
    <>
      Nel 2004 "quasi per gioco", con l'aiuto dei figli abbiamo aperto un piccolo punto vendita 
      ortofrutticolo a km0 situato a Borgo Gesso nel comune di Cuneo. Inizialmente i prodotti 
      erano pochi ma con il passare del tempo c'è stato un incremento dell'attività in modo tale 
      da coinvolggere a pieno tutta la famiglia<br/><br/>
      Il cliente, sin dall'inizio, con la sua fiducia e l'amore per il nostro lavoro ci ha dato lo 
      stimolo e il coraggio per aprire nel 2015 un nuovo punto vendita in Via Massimo d'Azzeglio
      9 Cuneo.<br/><br/>
      Vista la grande richiesta da parte dei nostri clienti, l'azienda ha deciso di trasferirsi 
      all'interno di essa in via Spinetta 55 Cuneo, ampliando il proprio punto vendita e dando la 
      possibilità di constatare con i propri occhi come vendono coltivati con amore e rispetto per 
      l'ambiente i nostri prodotti.<br/><br/>
      Nell'estate del 2018 è stato creato anche un laboratorio artigianale per la trasformazione 
      di frutta e verdura che potete trovare all'interno dei punti vendita.
    </>
  )
  return (
    <DescriptivePage fill
      mainImage={chiSiamoPic}
      text={text}
      title='Chi Siamo' />
  )
}

export default WhoAreWe