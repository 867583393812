import { Box, Image, Stack } from "grommet";
import { Close, Next, Previous } from "grommet-icons";

const GalleryImage = ({ image: { urls }, closeHandler, nextHandler, prevHandler, nextVisible, prevVisible }) => {
    const imageUrl = urls.full;
    const closeIcon = (
        <Box
            margin="large"
            background={{ color: "dark-1" }}
            opacity="medium"
            pad="small">
            <Close 
                size="medium" 
                onClick={closeHandler} 
                cursor="pointer" />
        </Box>
    );
    const prevIcon = (
        <Box
            margin="large"
            background={{ color: "dark-1" }}
            opacity="medium"
            pad="small">
            <Previous 
                size="medium" 
                onClick={prevHandler} 
                cursor="pointer" />
        </Box>
    );
    const nextIcon = (
        <Box
            margin="large"
            background={{ color: "dark-1" }}
            opacity="medium"
            pad="small">
            <Next 
                size="medium" 
                onClick={nextHandler} 
                cursor="pointer" />
        </Box>
    );


    return (
        <Box flex justify="center" width="large">
            <Stack anchor="left">
                <Stack anchor="right">
                    <Stack anchor="top-right">
                        <Box>
                            <Image src={imageUrl} fill />
                        </Box>
                        {closeIcon}
                    </Stack> 
                    {nextVisible ? nextIcon : (<></>)}
                </Stack>
                {prevVisible ? prevIcon : (<></>)}
            </Stack>
        </Box>
    )
}

export default GalleryImage