/* eslint-disable */
import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

export const grommetTheme = deepMerge(grommet, {
  global: {
    font: {
        family: 'Roboto',
    },
        colors: {
            brand: '#007332',
            logo: '#e12b55',
            white: '#FFFFFF',
            focus: "none",
    },
    },
    focus: {
        outline: 'none',
    },
    button: {
        primary: {
            background: { color: 'brand' },
            border: {
                width: '0px',
                radius: '0px'
            },
            padding: {
                vertical: '4px',
                horizontal: '22px'
            },
        },
        secondary: {
            color: 'white',
            border: {
                color: 'white',
                width: '1px',
                radius: '0px'
            },
            padding: {
                vertical: '4px',
                horizontal: '22px'
            },
        },
        extend: grommet.button.extend,
        default: grommet.button.default || {},
    },
    page: {
        wide: {
            alignSelf: 'end'
        }
    }
});