import React from 'react'
import { Box, ResponsiveContext } from 'grommet';
import RouterCard from './RouterCard'
import WEBSITE_STRING_CONSTANTS from '../resources/strings';
import chiSiamoPic from '../resources/images/ChiSiamoCard.jpg';
import galleriaPic from '../resources/images/GalleriaCard.jpg';
import cosaFacciamoPic from '../resources/images/CosaFacciamoCard.jpg';
import ResponsiveGrid from './ResponsiveGrid';

const RouterCardContainer = () => {
    const card1 = (
        <RouterCard 
            gridArea='first'
            title={WEBSITE_STRING_CONSTANTS.ROUTER_CARD_1_TITLE} 
            backgroundImg={chiSiamoPic}
            href='/chi-siamo' />
    )
    const card2 = (
        <RouterCard 
            gridArea='second'
            title={WEBSITE_STRING_CONSTANTS.ROUTER_CARD_2_TITLE} 
            backgroundImg={galleriaPic}
            href='/galleria' />
    )
    const card3 = (
        <RouterCard 
            gridArea='third'
            title={WEBSITE_STRING_CONSTANTS.ROUTER_CARD_3_TITLE} 
            backgroundImg={cosaFacciamoPic}
            href='/cosa-facciamo' />
    )

    const size = React.useContext(ResponsiveContext);
    let marginSize;
    switch (size) {
        case 'small':
            marginSize = { left: 'xlarge', right: 'xlarge', top: 'xlarge', bottom: 'xlarge' };
            break;
        case 'medium':
            marginSize = { left: 'large', right: 'large', top: 'xlarge', bottom: 'xlarge' };
            break;
        case 'large':
            marginSize = { left: 'xlarge', right: 'xlarge', top: 'xlarge', bottom: 'xlarge' };
            break;
        default:
            break;
    }
    let gapSize;
    switch (size) {
        case 'small':
            gapSize = { row: 'large', column: 'none' };
            break;
        case 'medium':
            gapSize = { row: 'none', column: 'small' };
            break;
        case 'large':
            gapSize = { row: 'none', column: 'medium' };
            break;
        default:
            break;
    }

    return (
        <Box 
            background='logo'>
            <ResponsiveGrid
                responsive={true}
                rows={['auto', 'auto', 'auto']}
                columns={['auto', 'auto', 'auto']}
                gap={gapSize}
                margin={marginSize}
                areas={{
                    small: [
                        { name: 'first', start: [0, 0], end: [2, 0] },
                        { name: 'second', start: [0, 1], end: [2, 1] },
                        { name: 'third', start: [0, 2], end: [2, 2] },
                    ],
                    medium: [
                        { name: 'first', start: [0, 0], end: [0, 2] },
                        { name: 'second', start: [1, 0], end: [1, 2] },
                        { name: 'third', start: [2, 0], end: [2, 2] },
                    ],
                    large: [
                        { name: 'first', start: [0, 0], end: [0, 2] },
                        { name: 'second', start: [1, 0], end: [1, 2] },
                        { name: 'third', start: [2, 0], end: [2, 2] },
                    ],
                }}>
                    {card1}
                    {card2}
                    {card3} 
            </ResponsiveGrid>
        </Box>
    )
}

export default RouterCardContainer