import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'

const mapContainerStyle = {
    width: '100%',
    height: '100%',
}

const center = {
    lat: 44.38363875702692,
    lng: 7.5677632092860625,
}

const Map = () => {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })
    
    if (loadError) return 'Error loading maps';
    if (isLoaded) {
        return (
            <GoogleMap 
                mapContainerStyle={mapContainerStyle}
                zoom={17}
                center={center}>
                <Marker position={center} />
            </GoogleMap>
        )
    }
    
    return (
        <></>
    )
}

export default Map