import { Box } from 'grommet';
import B2BSection from '../components/B2BSection';
import DescriptionWithImage from '../components/DescriptionWithImage';
import Map from '../components/map/Map';
import RouterCardContainer from '../components/RouterCardContainer';
import WEBSITE_STRING_CONSTANTS from '../resources/strings';
import cascinaLinguaPic from '../resources/images/cascinaLingua.jpg';
import TeamPic from '../resources/images/team.jpg';
import Banner from '../components/Banner';

const HomePage = () => {
  return (
    <Box>
      <Box height='600px'>
        <Banner />
      </Box>
      <DescriptionWithImage 
        imgSrc={cascinaLinguaPic} 
        title={WEBSITE_STRING_CONSTANTS.CASCINA_LINGUA_TITLE_HOMEPAGE} 
        desc={WEBSITE_STRING_CONSTANTS.CASCINA_LINGUA_DESC_HOMEPAGE}
        inverse={false} />
      <B2BSection />
      <DescriptionWithImage 
        imgSrc={TeamPic} 
        title={WEBSITE_STRING_CONSTANTS.SCOPRI_IL_TEAM_TITLE_HOMEPAGE} 
        desc={WEBSITE_STRING_CONSTANTS.SCOPRI_IL_TEAM_DESC_HOMEPAGE} 
        inverse={true} />
      <RouterCardContainer />
      <Box height='medium'>
        <Map />
      </Box>
    </Box>
  )
}

export default HomePage