import React from 'react';
import { Card, CardBody, CardFooter, Image, Heading, Text, ResponsiveContext } from 'grommet';

const ProductCard = ({ imgSrc, title, desc }) => {
  const size = React.useContext(ResponsiveContext);
  let picHeight;
  switch (size) {
    case 'small':
      picHeight = '320px';
      break;
    case 'medium':
      picHeight = '290px';
      break;
    case 'large':
      picHeight = '400px';
      break;
    default:
      break;
  }

  return (
    <Card fill>
        <CardBody flex={false} height={picHeight}>
            <Image fill src={imgSrc} fit='cover' />
        </CardBody>
        <CardFooter flex='grow' pad='small' direction='column' justify='start'>
            <Heading textAlign='center' level='5' margin='none'>{title}</Heading>
            <Text textAlign='center' size='small'><b>Ingredienti: </b>{desc}</Text>
        </CardFooter>
    </Card>
  )
}

export default ProductCard