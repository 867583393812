import React, { useState } from 'react';
import { Box, Layer, Grid, Keyboard, ResponsiveContext } from 'grommet';
import GalleryImage from '../components/gallery/GalleryImage';
import Thumbnail from '../components/gallery/Thumbnail';
import IMAGES from '../resources/images_gallery';


const Gallery = ({ gridArea }) => {
    const [activeImageId, setActiveImageId] = useState(null);
    const imageIsActive = (image) => {
        return activeImageId === image.id && activeImageId !== null;
    };

    const findCurrrentIndex = (currentImage) => {
        return IMAGES.findIndex(
            (image) => image.id === currentImage.id
        );
    }

    const navigate = (direction, currentImage) => {
        // Find next image
        const currentIndex = findCurrrentIndex(currentImage);
    
        const targetImage =
            IMAGES[direction === 'next' ? currentIndex + 1 : currentIndex - 1];
    
        if (targetImage) {
            setActiveImageId(targetImage.id);
        }
    };

    const size = React.useContext(ResponsiveContext);
    const columnsCount = size === 'small' ? 1 : 5;
    return (
        <Grid 
            gridArea={ gridArea }
            gap='small'
            rows='small'
            columns={{ count: columnsCount, size: 'auto' }}
            margin='medium'>
            {IMAGES.map((image) => (
                <Box key={image.id}>
                    {imageIsActive(image) && (
                        <Layer
                            onClickOutside={() => setActiveImageId(null)}
                            animate={false}>
                            <Keyboard
                                onRight={() => navigate('next', image)}
                                onLeft={() => navigate('prev', image)}
                                onEsc={() => setActiveImageId(null)}
                                target='document'>
                                <GalleryImage
                                    image={image}
                                    nextVisible = {IMAGES[findCurrrentIndex(image) + 1]}
                                    prevVisible = {IMAGES[findCurrrentIndex(image) - 1]}
                                    nextHandler={() => navigate('next', image)}
                                    prevHandler={() => navigate('prev', image)}
                                    closeHandler={() => setActiveImageId(null)} />
                            </Keyboard>
                        </Layer>
                    )}
                    <Box fill onClick={() => setActiveImageId(image.id)}>
                        <Thumbnail image={image} />
                    </Box>
                </Box>
            ))}
        </Grid>
    )
}

export default Gallery