import DescriptivePage from '../components/DescriptivePage'
import cosaFacciamoPic from '../resources/images/CosaFacciamo.jpg';

const WhatDoWeDo = ({ gridArea }) => {
  const text = (
    <>
      Per quanto riguarda i prodotti, l'azienda si è posta l'obiettivo di fornire 
      al cliente solamente i prodotti di stagione, raccolti freschi tutte le mattine
      e portati direttamente dal campo sugli scaffali dei punti vendita.<br/><br/>
      <p>
        <li>Frutta di stagione(ciliegie, albicocche, pesche, pere, prugne, susine, mele, kiwi, cachi...)</li>
        <li>Piccoli frutti di stagione (fragole, lamponi, more, mirtilli, ribes)</li>
        <li>Verdure di stagione (patate coltivate nei campi di Boves, fagioli, peperoni, insalate, 
          piselli, pomodori, zucchine, carote, porri, cipolle e molto altro)</li>
        <li>Confetture di vario genere</li>
        <li>Sottoli, sottaceti, vedure grigliate</li>
        <li>Preparati per minestre</li>
        <li>Verdure pronte per essere cucinate</li>
        <li>Succhi</li>
      </p>
      <br/>
      I nostri prodotti vengono raccolti ogni mattina per mantenere la loro freschezza.
    </>
  )
  return (
    <DescriptivePage fill
      gridArea={gridArea}
      mainImage={cosaFacciamoPic}
      text={text}
      title='Cosa Facciamo' />
  )
}

export default WhatDoWeDo