import React from 'react'
import { Box, Form, FormField, TextInput, Button, TextArea } from 'grommet';
import emailjs from '@emailjs/browser';
import Lottie from "lottie-react";
import TickAnimation from "../resources/animations/tick.json";

export const EmailForm = () => {
    const [value, setValue] = React.useState({ user_name: '', user_email: '', message: ''});
    const [showTickAnimation, setShowTickAnimation] = React.useState(false);
    const form = React.useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            form.current, 
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
          .then((result) => {
              console.log(result.text);
              setShowTickAnimation(true);
          }, (error) => {
              console.log(error.text);
          });
      };

    const formHTML = (
        <Box width='large'>
            <Form
                ref={form}
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({user_name: '', user_email: '', message: ''})}
                onSubmit={sendEmail}>
                <FormField label='Nome' name='user_name' required>
                    <TextInput name='user_name' type='name' />
                </FormField>
                <FormField label="Email" name="user_email" required>
                    <TextInput name="user_email" type="email" />
                </FormField>
                <FormField label="Messaggio" name="message" required>
                    <TextArea name="message" type="text" />
                </FormField>
                <Box margin={{ top: 'xlarge' }} direction='row' gap='medium'>
                    <Button type='submit' primary label='Invia' />
                    <Button type='reset' label='Cancella' />
                </Box>
            </Form>
        </Box>
    );

    const tickStyle = {
        height: '300px',
      };
    const tickAnimationHTML = (
        <Box direction='row' alignContent='start'>
            <Lottie animationData={TickAnimation} loop={false} style={tickStyle} />
        </Box>
    );

    return (
        <Box height='353px'>
            {showTickAnimation ? tickAnimationHTML : formHTML}
        </Box>
    )
}
