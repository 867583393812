import React from 'react'
import { Box, Layer, Button, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';
import PropTypes from 'prop-types'

const SideBar = ({setShowSidebar, gridArea}) => {
    return (
        <Layer 
            gridArea={gridArea}
            position='right'
            modal={false}
            responsive={false}
            full='vertical'
            background='brand'
            onClickOutside={() => setShowSidebar(false)}>
            <Box
                fill='vertical'
                width='medium'>
                <Button icon={<FormClose color='white' />} 
                        onClick={() => setShowSidebar(false)}
                        alignSelf='end' />
                <Box 
                    pad='medium'
                    gap='medium'>
                    <Anchor color='white' hoverIndicator href="/chi-siamo">Chi Siamo</Anchor>
                    <Anchor color='white' hoverIndicator href="/galleria">Galleria</Anchor>
                    <Anchor color='white' hoverIndicator href="/cosa-facciamo">Cosa Facciamo</Anchor>
                    <Anchor color='white' hoverIndicator href='/prodotti'>I Nostri Prodotti</Anchor>
                    <Anchor color='white' hoverIndicator href="/contatti">Contatti</Anchor>
                </Box>
            </Box>
        </Layer>
    
    )
}

SideBar.propTypes = {
    hideSideBar: PropTypes.func,
    showSidebar: PropTypes.bool
}

export default SideBar