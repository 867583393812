import React from 'react';
import { Stack, Image, Box, ResponsiveContext } from 'grommet';

const ImageWithOffsetBackground = ({ gridArea, imgSrc }) => {
  const size = React.useContext(ResponsiveContext);
  let responsivePad;
  switch (size) {
    case 'small':
      responsivePad = { left: 'small', right:'large', bottom:'small', top: 'small' };
      break;
    case 'medium':
      responsivePad = { left: 'none', right:'small', bottom:'small', top: 'small' };
      break;
    case 'large':
      responsivePad = { left: 'xlarge', right:'xlarge', bottom:'none', top: 'none' };
      break;
    default:
      break;
  }
  let picHeight;
  switch (size) {
    case 'small':
      picHeight = '300px';
      break;
    case 'medium':
      picHeight = '380px';
      break;
    case 'large':
      picHeight = '500px';
      break;
    default:
      break;
  }
  return (
    <Box align="center" 
         alignSelf='center'
         gridArea={gridArea}
         pad={responsivePad}
         height={picHeight}>
        <Stack fill>
            <Box background='brand' margin={{ top: '6%' }} width='97%' height='100%'/>
            <Image margin={{ left: '5%' }}
                src={imgSrc} 
                style={{ width: '100%', objectFit: 'cover', objectPosition: 'top', height: '100%' }} />
        </Stack>
    </Box>

  )
}

export default ImageWithOffsetBackground