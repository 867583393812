import { Anchor } from 'grommet';

const NavBarAnchor = ({title, href}) => {
  return (
    <Anchor 
        hoverIndicator
        color='white' 
        href={href}>
        {title}
    </Anchor>
  )
}

export default NavBarAnchor