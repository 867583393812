import antipastoPiemontesePic from '../resources/images/products/AntipastoPiemontese.jpg';
import bevandaMirtilloPic from '../resources/images/products/BevandaMirtillo.jpg';
import compostaFichiPic from '../resources/images/products/CompostaFichi.jpg';
import giardinieraPic from '../resources/images/products/Giardiniera.jpg';
import mirtilloSottoSolePic from '../resources/images/products/MirtilloSottoSole.jpg';
import pescheSciroppatePic from '../resources/images/products/PescheSciroppate.jpg';
import prugneSciroppatePic from '../resources/images/products/PrugneSciroppate.jpg';
import salsaPomodoroCuoreBuePic from '../resources/images/products/SalsaPomodoroCuoreBue.jpg';
import salsaPomodoroDatterinoPic from '../resources/images/products/SalsaPomodoroDatterino.jpg';
import bevandaPeraPic from '../resources/images/products/SuccoPera.jpg';
import bevandaPrugnaPic from '../resources/images/products/SuccoPrugna.jpg';
import antipastoZucchine from '../resources/images/products/AntipastoZucchine.jpeg';
import compostaMelePere from '../resources/images/products/CompostaMelaPera.jpeg';
import compostaPere from '../resources/images/products/CompostaPere.jpeg';
import compostaPesche from '../resources/images/products/CompostaPesche.jpeg';
import succoMela from '../resources/images/products/SuccoMela.jpeg';
import compostaFragole from '../resources/images/products/CompostaFragole.jpeg';
import compostaMirtilli from '../resources/images/products/CompostaMirtilli.jpeg';
import compostaPeraCioccolato from '../resources/images/products/CompostaPeraCioccolato.jpeg';
import compostaPeraZenzero from '../resources/images/products/CompostaPeraZenzero.jpeg';
import compostaPersipien from '../resources/images/products/CompostaPersipien.jpeg';
import compostaPiccoliFrutti from '../resources/images/products/CompostaPiccoliFrutti.jpeg';
import compostaUva from '../resources/images/products/CompostaUva.jpeg';
import melanzanePiccanti from '../resources/images/products/MelanzanePiccanti.jpeg';
import compostaCiliegie from '../resources/images/products/CompostaCiliegie.jpeg';
import bevandaAlbicocca from '../resources/images/products/BevandaAlbicocca.jpeg';
import bevandaPesca from '../resources/images/products/BevandaPesca.jpeg';
import ciliegieSottoZucchero from '../resources/images/products/CiliegieSottoZucchero.jpeg';
import compostaSusine from '../resources/images/products/CompostaSusine.jpeg';
import salsaPomodoroDatterinoGiallo from '../resources/images/products/SalsaPomodoroDatterinoGiallo.jpeg';

const PRODUCTS = [
    {
        id: '1',
        imgSrc: antipastoZucchine,
        title: 'ANTIPASTO DI ZUCCHINE',
        desc: 'Zucchine 70%, Cipolla, Aglio, Basilico, Peperone, Pepe, Sale, Zucchero, Aceto Bianco, Olio di Oliva.'
    },
    {
        id: '3',
        imgSrc: compostaMelePere,
        title: 'COMPOSTA DI MELE E PERE',
        desc: 'Mela 50%, Pera 50%, Zucchero di Canna.'
    },
    {
        id: '6',
        imgSrc: compostaPere,
        title: 'COMPOSTA DI PERE',
        desc: 'Pera, Zucchero di Canna.'
    },
    {
        id: '7',
        imgSrc: compostaPesche,
        title: 'COMPOSTA DI PESCHE',
        desc: 'Pesca Pasta Gialla, Zucchero di Canna.'
    },
    {
        id: '8',
        imgSrc: salsaPomodoroDatterinoPic,
        title: 'SALSA DI POMODORO VARIETÀ DATTERINO',
        desc: 'Datterino.'
    },
    {
        id: '9',
        imgSrc: succoMela,
        title: 'SUCCO DI MELE',
        desc: 'Mele, Acido Ascorbico 15g/100l.'
    },
    {
        id: '10',
        imgSrc: compostaFragole,
        title: 'COMPOSTA DI FRAGOLE',
        desc: 'Fragole, Zucchero di Canna.'
    },
    {
        id: '11',
        imgSrc: compostaMirtilli,
        title: 'COMPOSTA DI MIRTILLI',
        desc: 'Mirtilli, Zucchero di Canna.'
    },
    {
        id: '12',
        imgSrc: compostaPeraCioccolato,
        title: 'COMPOSTA DI PERE E CIOCCOLATO',
        desc: 'Pera 90%, Cioccolato Amaro 10%, Zucchero di Canna.'
    },
    {
        id: '13',
        imgSrc: compostaPeraZenzero,
        title: 'COMPOSTA DI PERE E ZENZERO',
        desc: 'Pera 99%, Zenzero Fresco 1%, Zucchero di Canna.'
    },
    {
        id: '14',
        imgSrc: compostaPersipien,
        title: 'COMPOSTA DI PESCA E AMARETTO',
        desc: 'Pesca 80%, Amaretto 10%, Cacao Amaro 10%, Zucchero di Canna.'
    },
    {
        id: '15',
        imgSrc: compostaPiccoliFrutti,
        title: 'COMPOSTA DI PICCOLI FRUTTI',
        desc: 'Mora, Lampone, Mirtilli, Zucchero di Canna.'
    },
    {
        id: '16',
        imgSrc: compostaUva,
        title: 'COMPOSTA DI UVA FRAGOLA',
        desc: 'Uva Fragola, Zucchero di Canna.'
    },
    {
        id: '18',
        imgSrc: melanzanePiccanti,
        title: 'FILETTI DI MELANZANE PICCANTI',
        desc: 'Melanzane, Aglio, Prezzemolo, Peperoncino, Olio di Oliva, Aceto Bianco, Sale'
    },
    {
        id: '19',
        imgSrc: compostaCiliegie,
        title: 'COMPOSTA DI CILIEGIE DURONE',
        desc: 'Ciliegia Durone, Zucchero di Canna.'
    },
    {
        id: '20',
        imgSrc: salsaPomodoroCuoreBuePic,
        title: 'SALSA DI POMODORO CUORE DI BUE',
        desc: 'Cuore di Bue, Sale'
    },
    {
        id: '22',
        imgSrc: giardinieraPic,
        title: 'GIARDINIERA',
        desc: 'Carote, Sedano, Zucchine, Peperone, Cipolla, Pomodoro, Sale, Aceto, Vino Bianco.'
    },
    {
        id: '24',
        imgSrc: bevandaPeraPic,
        title: 'BEVANDA ANALCOLICA ALLA PERA',
        desc: 'Pera, Zucchero di Canna.'
    },
    {
        id: '25',
        imgSrc: bevandaPesca,
        title: 'BEVANDA ANALCOLICA ALLA PESCA',
        desc: 'Pesca, Zucchero di Canna.'
    },
    {
        id: '26',
        imgSrc: bevandaAlbicocca,
        title: 'BEVANDA ANALCOLICA ALL\' ALBICOCCA',
        desc: 'Albicocca, Zucchero di Canna.'
    },
    {
        id: '27',
        imgSrc: antipastoPiemontesePic,
        title: 'ANTIPASTO PIEMONTESE',
        desc: 'Carota, Cavolfiore, Sedanom, Zucchini, Peperone, Cipolla, Pomodoro, Sale, Zucchero, Aceto bianco.'
    },
    {
        id: '28',
        imgSrc: bevandaMirtilloPic,
        title: 'BEVANDA ANALCOLICA AL MIRTILLO',
        desc: 'Mirtilli, Zucchero di Canna.'
    },
    {
        id: '29',
        imgSrc: compostaFichiPic,
        title: 'COMPOSTA DI FICHI',
        desc: 'Fichi, Zucchero di Canna.'
    },
    {
        id: '30',
        imgSrc: mirtilloSottoSolePic,
        title: 'MIRTILLO SOTTO SOLE',
        desc: 'Mirtilli, Zucchero.'
    },
    {
        id: '31',
        imgSrc: pescheSciroppatePic,
        title: 'PESCHE SCIROPPATE',
        desc: 'Pesche, Zucchero.'
    },
    {
        id: '32',
        imgSrc: prugneSciroppatePic,
        title: 'PRUGNE SCIROPPATE',
        desc: 'Prugne, Zucchero.'
    },
    {
        id: '33',
        imgSrc: bevandaPrugnaPic,
        title: 'SUCCO DI PRUGNA',
        desc: 'Prugne, Acqua, Zucchero.'
    },
    {
        id: '34',
        imgSrc: ciliegieSottoZucchero,
        title: 'CILIEGIE SOTTO ZUCCHERO',
        desc: 'Ciliegie, Zucchero.'
    },
    {
        id: '35',
        imgSrc: compostaSusine,
        title: 'COMPOSTA DI SUSINE',
        desc: 'Susine, Zucchero.'
    },
    {
        id: '35',
        imgSrc: salsaPomodoroDatterinoGiallo,
        title: 'SALSA DI POMODORO VARIETÀ DATTERINO GIALLO',
        desc: 'Pomodoro, Sale.'
    }
];

export default PRODUCTS;